import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-styled-flexboxgrid";
import { ThemeProvider } from "styled-components";
import theme from "../../utils/theme.js";
import Navigation from "../Navigation";
import Footer from "../Footer";

const propTypes = {
  children: PropTypes.func.isRequired,
};

const MainLayout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Row>
      <Col xs={12}>
        <Navigation /> {children}
        <Footer />
      </Col>
    </Row>
  </ThemeProvider>
);

MainLayout.propTypes = propTypes;

export default MainLayout;

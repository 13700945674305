module.exports = {
  global: {
    colors: {
      shark: "#19191a",
      mineShaft: "#313131",
      codGray: "#1c1c1c",
      white: "#ffffff",
      greenApple: "#6CBB40",
      black: "#000000",
      transparent: "rgba(0,0,0,0)",
      silverChalice: "#a7a7a7",
      brand: "#6cbb40"
    },
    font: { family: "Arial" }
  },
  button: {
    default: {
      padding: {
        horizontal: "1rem",
        vertical: "1.25rem"
      }
    }
  },
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
    mediaQuery: "only screen",
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 78 // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 77 // em
    }
  }
};

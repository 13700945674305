import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { navigateTo } from "gatsby-link";
import cuid from "cuid";
import { Navigation, MobileNavigation } from ".";

class NavContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navMenu: [
        {
          id: cuid(),
          route: null,
          customClass: null,
          title: "MJ",
          dd: [
            {
              id: cuid(),
              route: "/mj",
              customClass: "mj",
              title: "About MJ",
            },
            {
              id: cuid(),
              route: "/philosophy",
              customClass: "philosophy",
              title: "Philosophy",
            },
          ],
        },

        {
          id: cuid(),
          route: "/hand-surgery",
          customClass: "hand-surgery",
          title: "Hand Surgery",
          dd: null,
        },
        {
          id: cuid(),
          route: "/mediation",
          customClass: "mediation",
          title: "Mediation",
          dd: null,
        },
        {
          id: cuid(),
          route: "/touchless",
          customClass: "touchless",
          title: "Go Touchless",
          dd: null,
        },
        {
          id: cuid(),
          route: null,
          customClass: null,
          title: "Sports",
          dd: [
            {
              id: cuid(),
              route: "/agency",
              customClass: "agency",
              title: "Agency",
            },
            {
              id: cuid(),
              route: "/horse-racing",
              customClass: "horseracing",
              title: "Equine Spirit",
            },
            {
              id: cuid(),
              route: "/fashion",
              customClass: "fashion",
              title: "Golf",
            },
            {
              id: cuid(),
              route: "/shop",
              customClass: "shop",
              title: "Shop",
            },
          ],
        },
        {
          id: cuid(),
          route: "/voice",
          customClass: "voice",
          title: "Voice",
          dd: null,
        },

        {
          id: cuid(),
          route: "/books-mags",
          customClass: "books-mags",
          title: "Books & Mags",
          dd: null,
        },

        {
          id: cuid(),
          route: "/zen",
          customClass: "zen",
          title: "Zen",
          dd: null,
        },

        {
          id: cuid(),
          route: "/reach-us",
          customClass: "reachus",
          title: "Reach Us",
          dd: null,
        },
      ],
      mobileMenuOpen: false,
    };
  }

  openMobileMenu = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    });
  };

  mobileNavigateTo = (route) => {
    navigateTo(`/${route}`);
    this.setState({
      mobileMenuOpen: false,
    });
  };

  render() {
    const { navMenu, mobileMenuOpen } = this.state;
    return (
      <section>
        <MediaQuery query="(max-device-width: 1224px)">
          <MobileNavigation
            navMenu={navMenu}
            mobileMenuOpen={mobileMenuOpen}
            controlFunc={this.openMobileMenu}
            mobileNavControlFunc={this.mobileNavigateTo}
          />
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1225px)">
          <Navigation navMenu={navMenu} />
        </MediaQuery>
      </section>
    );
  }
}

export default NavContainer;

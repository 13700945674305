import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { navigate } from "gatsby-link";
import { Row, Col } from "react-styled-flexboxgrid";
import { rem } from "polished";

import MobileMenu from "../../images/mobile-menu.svg";
import MobileClose from "../../images/mobile-close.svg";
import MobileLogoSvg from "../../images/logo.svg";

// theme

const navArray = PropTypes.shape({
  id: PropTypes.string,
  route: PropTypes.string,
  customClass: PropTypes.string,
  title: PropTypes.string,
});

const propTypes = {
  navMenu: PropTypes.arrayOf(navArray).isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
  controlFunc: PropTypes.func.isRequired,
  mobileNavControlFunc: PropTypes.func.isRequired,
};

const NavContainer = styled.nav`
  background: black;
  position: fixed;
  z-index: 9000;
  width: 100%;
  button {
    background: black;
    border: 0;
    width: ${rem(70)};
    margin: 0.5rem;
    img {
      margin: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;
const MobileNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  background-color: black;
`;

const DDMenu = styled.ul`
  // height: 100vh;
  background: ${({ theme }) => theme.global.colors.shark};
  opacity: 1;
  top: ${(props) => props.styledTop}px;
  transition: opacity 500ms easeIn;
  margin: 0;
  position: absolute;
  z-index: 9900;
  width: 100%;
  font-family: Raleway, sans-serif;
  li {
    list-style: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 0;
    button {
      background: ${({ theme }) => theme.global.colors.shark};
      padding: 1rem;
      color: white;
      display: block;
      width: 100%;
      text-align: left;
      margin: 0;
    }
  }
`;

const DDSubMenu = styled(DDMenu)`
  position: relative;
  background-color: #2f2f2f;
  li {
    button {
      margin-left: 16px;
      background: transparent;
    }
  }
`;

const MobileMenuIcon = styled.img`
  height: ${rem(30)};
`;

const MobileNavLogo = styled.img`
  height: ${rem(50)};
  margin: ${rem(10)} ${rem(10)} ${rem(10)} ${rem(20)};
`;

// const NavMenuItem = (props) => {
//   const [open, setOpen] = useState(false);
//   const { item } = props;

//   function handleDropDown() {
//     console.log("clicked");
//     setOpen(!open);
//   }
//   return (
//     <li key={item.id}>
//       <Flex>
//         {item.dd && (
//           <Flex
//             flexDirection="column"
//             alignItems="center"
//             justifyContent="center"
//           >
//             <StyledDDIcon
//               src={open ? DDIcon : DDIconRight}
//               open={open}
//               title="dropdown arrow"
//             />
//           </Flex>
//         )}
//         <Box position="relative">
//           {item.dd === null ? (
//             <Link className={item.customClass} to={item.route}>
//               {item.title}
//             </Link>
//           ) : (
//             <NavMenuButton
//               className={item.customClass}
//               onClick={() => handleDropDown()}
//             >
//               {item.title}
//             </NavMenuButton>
//           )}

//           {open && (
//             <DDMenu>
//               {item.dd.map((el) => (
//                 <li>
//                   <Link className={el.customClass} to={el.route}>
//                     {el.title}
//                   </Link>
//                 </li>
//               ))}
//             </DDMenu>
//           )}
//         </Box>
//       </Flex>
//     </li>
//   );
// };

const MobileNav = (props) => {
  const { navMenu, mobileMenuOpen, controlFunc } = props;

  return (
    <NavContainer>
      <Row>
        <Col xs={12}>
          <MobileNavContainer>
            <button
              onClick={() => {
                navigate("/");
              }}
            >
              <MobileNavLogo src={MobileLogoSvg} alt="MJ Global logo" />
            </button>

            <button onClick={controlFunc}>
              <MobileMenuIcon
                src={!mobileMenuOpen ? MobileMenu : MobileClose}
                alt="mobile menu"
              />
            </button>
          </MobileNavContainer>
        </Col>
      </Row>

      {mobileMenuOpen && (
        <DDMenu>
          {navMenu.map((item) => (
            <>
              <li key={item.id}>
                <button
                  className={item.customClass}
                  onClick={() => navigate(item.route)}
                >
                  {item.title}
                </button>
                {item.dd && (
                  <DDSubMenu>
                    {item.dd.map((el) => (
                      <li key={el.id}>
                        <button
                          className={el.customClass}
                          onClick={() => navigate(el.route)}
                        >
                          {el.title}
                        </button>
                      </li>
                    ))}
                  </DDSubMenu>
                )}
              </li>
            </>
          ))}
        </DDMenu>
      )}
    </NavContainer>
  );
};

MobileNav.propTypes = propTypes;

export default MobileNav;

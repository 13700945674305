import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { rem } from "polished";

const propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.number
};

const SectionContainer = styled.section`
  max-width: ${props => rem(props.maxWidth)};
  display: block;
  margin: 0 auto;
`;

const Container = props => {
  const { children, maxWidth } = props;
  return <SectionContainer maxWidth={maxWidth}>{children}</SectionContainer>;
};

Container.defaultProps = {
  maxWidth: 940
};
Container.propTypes = propTypes;

export default Container;

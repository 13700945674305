import React, { useState } from "react";
import Link from "gatsby-link";
import PropTypes from "prop-types";
import { Col, Row } from "react-styled-flexboxgrid";
import { Box, Flex } from "reflexbox";
import styled from "styled-components";
import { rem } from "polished";
import DDIcon from "../../images/dd-icon.svg";
import DDIconRight from "../../images/dd-icon-right.svg";
import Logo from "../../images/logo.svg";
import Container from "../Container";

const NavSection = styled.section`
  background-color: ${({ theme }) => theme.global.colors.shark};
  color: ${({ theme }) => theme.global.colors.white};
  padding: ${rem(16)};
`;

const NavMenu = styled.ul`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-left: 0;
  margin: 0;
  li {
    position: relative;
    list-style: none;
    margin-bottom: 0;
    margin-right: 2rem;
    a {
      background-color: transparent;
      color: ${({ theme }) => theme.global.colors.white};
      line-height: 2.5;
      text-decoration: none;
      display: block;
      padding: 0.5rem;
      border: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      font-family: Raleway, sans-serif;
      letter-spacing: 0.04em;
      transition: color 0.25s ease-out, color 0.25s ease-out;
      &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.global.colors.greenApple};
      }
    }
  }
  @media screen and (max-width: 39.9375em) {
    display: none;
  }
`;

const NavMenuButton = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.global.colors.white};
  line-height: 2.5;
  text-decoration: none;
  display: block;
  padding: ${rem(8)};
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: ${rem(14)};
  font-family: Raleway, sans-serif;
  letter-spacing: 0.04em;
  transition: color 0.25s ease-out, color 0.25s ease-out;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.global.colors.greenApple};
  }
`;

const StyledLogo = styled.img`
  margin: 0;
`;

const StyledDDIcon = styled.img`
  height: ${rem(10)};
  width: ${rem(10)};
  display: inline-block;
  background-color: ${({ theme }) => theme.global.colors.shark};
`;

const DDMenu = styled.ul`
  background-color: ${({ theme }) => theme.global.colors.shark};
  border: 1px solid #717171;
  position: absolute;
  z-index: 9000;
  box-shadow: 1px 1px 1px #717171;
  min-width: ${rem(130)};
  left: 0;
  margin: 0;
  li {
    margin-right: 0;
  }
`;

const navArray = PropTypes.shape({
  id: PropTypes.string,
  route: PropTypes.string,
  customClass: PropTypes.string,
  title: PropTypes.string,
});

const propTypes = {
  navMenu: PropTypes.arrayOf(navArray).isRequired,
};

const NavMenuItem = (props) => {
  const [open, setOpen] = useState(false);
  const { item } = props;

  function handleDropDown() {
    console.log("clicked");
    setOpen(!open);
  }
  return (
    <li key={item.id}>
      <Flex>
        {item.dd && (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <StyledDDIcon
              src={open ? DDIcon : DDIconRight}
              open={open}
              title="dropdown arrow"
            />
          </Flex>
        )}
        <Box position="relative">
          {item.dd === null ? (
            <Link className={item.customClass} to={item.route}>
              {item.title}
            </Link>
          ) : (
            <NavMenuButton
              className={item.customClass}
              onClick={() => handleDropDown()}
            >
              {item.title}
            </NavMenuButton>
          )}

          {open && (
            <DDMenu>
              {item.dd.map((el) =>
                el.route !== null ? (
                  <li>
                    <Link className={el.customClass} to={el.route}>
                      {el.title}
                    </Link>
                  </li>
                ) : (
                  <a href={el.href} target="_blank" rel="noreferrer">
                    {el.title}
                  </a>
                )
              )}
            </DDMenu>
          )}
        </Box>
      </Flex>
    </li>
  );
};

const Navigation = (props) => {
  const { navMenu } = props;
  return (
    <NavSection>
      <Container maxWidth={1280}>
        <Row>
          <Col xs={12} md={1} lg={1}>
            <Link to="/">
              <StyledLogo src={Logo} alt="MJ Global Logo" width="50px" />
            </Link>
          </Col>

          <Col xs={12} md={10} lg={10}>
            <NavMenu>
              {navMenu.map((item) => (
                <NavMenuItem item={item} />
              ))}
            </NavMenu>
          </Col>
          <Col xs={12} md={1} lg={1}>
            {/* <Link to="/cart">
              <StyledCart src={IconShop} alt="shop" width={10} />
            </Link> */}
          </Col>
        </Row>
      </Container>
    </NavSection>
  );
};
Navigation.propTypes = propTypes;

export default Navigation;

import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import Container from "../Container";
import { rem } from "polished";
import Logo from "../../images/logo.svg";
import SocialFacebook from "../../images/social-facebook.svg";
import SocialInstagram from "../../images/s-instagram.svg";

const SectionFooter = styled.section`
  background-color: ${({ theme }) => theme.global.colors.shark};
  color: white;
  padding: 3rem 1rem;

  hr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  @media screen and (max-width: 39.9375em) {
    padding-bottom: 0.5rem;
    p {
      font-size: ${rem(13)};
    }
  }
`;

const FooterLogo = styled.img`
  width: ${rem(75)};
`;

const SocialMenu = styled.ul`
  display: flex;
  justify-content: flex-end;
  li {
    list-style: none;
    margin: auto 0.5rem;
  }
`;

const Footer = () => (
  <SectionFooter>
    <Container maxWidth={1280}>
      <MediaQuery query="(min-device-width: 1225px)">
        <Row>
          <Col xs={12}>
            <FooterLogo src={Logo} alt="footer logo" />
          </Col>
        </Row>
      </MediaQuery>

      <hr />
      <Row>
        <Col xs={7} md={6}>
          <p>{`MJ MD Global Management Group  © ${new Date().getFullYear()}`}</p>
        </Col>
        <Col xs={5} md={6}>
          <SocialMenu>
            <li>
              <img src={SocialFacebook} alt="Social Facebook" width="35" />
            </li>
            <li>
              <img src={SocialInstagram} alt="Social Instagram" width="35" />
            </li>
          </SocialMenu>
        </Col>
      </Row>
    </Container>
  </SectionFooter>
);

export default Footer;
